import * as THREE from "three";

const EmmisiveShader = {
	name: 'EmmisiveShader',
	uniforms: {
	},

	vertexShader: [

		`
		varying vec2 vUv;
  
		void main()
		{
			vec4 modelPosition = modelMatrix * vec4(position, 1.0);
			vec4 viewPosition = viewMatrix * modelPosition;
			vec4 projectionPosition = projectionMatrix * viewPosition;
			gl_Position = projectionPosition;
		
			vUv = uv;
		}`

	].join("\n"),

	fragmentShader: [
		`
		varying vec2 vUv;
		void main()
		{

			vec3 Color = vec3(10.5,1.5,1.5);
			gl_FragColor = vec4(Color, 1.0);
		}
		`

	].join("\n")

};
export { EmmisiveShader };
