import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//store all pages
import Scene2Page from './Pages/Scene2';

const App: React.FC = () => {
  return (
    <div className="main">
      <Router>
        <Switch>
          <Route path="/" exact component={Scene2Page} />
          <Route path="/scene2" exact component={Scene2Page} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
