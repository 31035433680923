
import * as THREE from 'three'

/******************************************************************************/
/*!
\brief  Store/load material data
*/
/******************************************************************************/
export default class Material
{
  /******************************************************************************/
  /*!
  \brief  constructor
  */
  /******************************************************************************/
  constructor(_options)
  {
    //set variables
    this.uniforms = _options.uniforms

    this.vertexShader = _options.vertexShader;
    this.fragmentShader = _options.fragmentShader;
    this.setMaterial();
  }
  /******************************************************************************/
  /*!
  \brief  set material
  */
  /******************************************************************************/
  setMaterial()
  {
    
    this.material = new THREE.ShaderMaterial({
        side: THREE.DoubleSide,
        uniforms: this.uniforms,
        uniformsNeedUpdate : true,
        vertexShader: this.vertexShader,
        fragmentShader: this.fragmentShader
    })
    this.material.uniformsNeedUpdate = true;
  }
  getMaterial()
  {
    return this.material;
  }

}