import * as THREE from "three";

const StandardShader = {
    name: 'StandardShader',
	uniforms: {
	},

	vertexShader: [

		`
		varying vec2 vUv;
  
		void main()
		{
			vec4 modelPosition = modelMatrix * vec4(position, 1.0);
			vec4 viewPosition = viewMatrix * modelPosition;
			vec4 projectionPosition = projectionMatrix * viewPosition;
			gl_Position = projectionPosition;
		
			vUv = uv;
		}`

	].join( "\n" ),

	fragmentShader: [
		`
		uniform sampler2D standardTexture;
		
		varying vec2 vUv;
		
		void main()
		{
			vec3 Color = texture2D(standardTexture, vUv).rgb;
		 
			gl_FragColor = vec4(Color, 1.0);
		}
		`

	].join( "\n" )

};
export { StandardShader };
