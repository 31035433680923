/*****************************************************************************/
/*!
\file LoadingManager.js
\author Theon Teo
\par email: theonteo96@gmail.com
\date 2021
\brief
This project contains portfolio / web-mobile responsive application
\Not for distribution
*/
/*****************************************************************************/
import * as THREE from 'three';
import React, { Component } from "react";
import './LoadingManager.css'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

// all assets imported here
import assets from './Assets.js'
import EventEmitter from './EventEmitter';

/******************************************************************************/
/*!
\brief  main loading manager
*/
/******************************************************************************/
class Loader extends EventEmitter {
    static instance
    constructor(_options) {
        super(_options);
        if (Loader.instance) {
            return Loader.instance
        }
        Loader.instance = this
        this.updateCallback = _options.updateCallback

        this.loaded = 0
        this.setLoader();
        this.loading = true
        this.groups = {}
        this.items = []
        this.groups.assets = [...assets]
        this.groups.loaded = []
        this.groups.current = null

        this.loadNextGroup()
        // Loader file end event
        this.on('fileEnd', (_resource, _data) => {
            let data = _data

            // Convert to texture
            if (_resource.type === 'texture') {
                if (!(data instanceof THREE.Texture)) {
                    data = new THREE.Texture(_data)
                }
                data.wrapS = THREE.RepeatWrapping
                data.wrapT = THREE.RepeatWrapping
                data.flipY = false
                data.needsUpdate = true
            }

            this.items[_resource.name] = data
            console.log(data);
            // Progress and event
            this.groups.current.loaded++
            this.trigger('progress', [this.groups.current, _resource, data])
        })

        // Loader all end event
        this.on('end', () => {
            this.groups.loaded.push(this.groups.current)

            // Trigger
            this.trigger('groupEnd', [this.groups.current])
            console.log(this.groups.assets.length)
            if (this.groups.assets.length > 0) {
                console.log("start loadingg!!")
                this.loadNextGroup()
            }
            else {
                this.trigger('end')
                this.loading = false
            }
        })
    }
    getAsset(name) {
        return this.items[name];
        for (const _itemKey in this.items) {
            console.log(_itemKey);
            const item = this.items[_itemKey].name
            if (item == name) {
                return this.items[item];
            }

        }
        return this.groups.current.items[name];
    }
    async findAsset(name, callback = null) {
        (async () => {
            console.log("waiting for model to load");
            while (this.loaded == 0 || (this.loaded < 1 && this.items == undefined) || (this.groups.current.items != undefined && this.loaded < this.groups.current.items.length))
                await new Promise(resolve => setTimeout(resolve, 5));

            console.log(this.groups.current.items);

            for (const _itemKey in this.groups.current.items) {
                console.log(_itemKey);
                const item = this.groups.current.items[_itemKey].name
                if (item == name) {
                    if (callback != null) {
                        callback(this.items[item]);
                    }
                    return this.items[item];
                }

            }

        })();
    }
    async waitForAssets(callback) {
        (async () => {
            console.log("waiting for loader to finish");
            while (this.loaded == 0 || (this.loaded < 1 && this.items == undefined) || (this.groups.current.items != undefined && this.loaded < this.groups.current.items.length))
                await new Promise(resolve => setTimeout(resolve, 5));

            callback();
        })();
    }
    loadNextGroup() {
        this.groups.current = this.groups.assets.shift()
        this.groups.current.toLoad = this.groups.current.items.length
        console.log(this.groups.current.items.length)
        this.groups.current.loaded = 0

        this.load(this.groups.current.items)
        this.loading = true
    }

    createInstancedMeshes(_children, _groups) {
        // Groups
        const groups = []

        for (const _group of _groups) {
            groups.push({
                name: _group.name,
                regex: _group.regex,
                meshesGroups: [],
                instancedMeshes: []
            })
        }

        // Result
        const result = {}

        for (const _group of groups) {
            result[_group.name] = _group.instancedMeshes
        }

        return result
    }

    destroy() {
        for (const _itemKey in this.items) {
            const item = this.items[_itemKey]
            if (item instanceof THREE.Texture) {
                item.dispose()
            }
        }
    }
    setLoader() {
        this.loaders = []
        // Images
        this.loaders.push({
            extensions: ['jpg', 'png'],
            action: (_resource) => {
                const image = new Image()

                image.addEventListener('load', () => {
                    this.fileLoadEnd(_resource, image)
                })

                image.addEventListener('error', () => {
                    this.fileLoadEnd(_resource, image)
                })

                image.src = _resource.source
            }
        })

       

        // Draco
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('draco/')
        dracoLoader.setDecoderConfig({ type: 'js' })

        this.loaders.push({
            extensions: ['drc'],
            action: (_resource) => {
                dracoLoader.load(_resource.source, (_data) => {
                    this.fileLoadEnd(_resource, _data)

                    DRACOLoader.releaseDecoderModule()
                })
            }
        })

        // GLTF
        const gltfLoader = new GLTFLoader()
        gltfLoader.setDRACOLoader(dracoLoader)
        this.loaders.push({
            extensions: ['glb', 'gltf'],
            action: (_resource) => {
                console.log("loading:", _resource.source)
                gltfLoader.load(_resource.source, (_data) => {
                    console.log(_resource.name)
                    this.fileLoadEnd(_resource, _data)
                }, undefined, function (error) {

                    console.log(error);
                })
            }
        })

        // FBX
        const fbxLoader = new FBXLoader()

        this.loaders.push({
            extensions: ['fbx'],
            action: (_resource) => {
                console.log("loading fbx")
                fbxLoader.load(_resource.source, (_data) => {
                    this.fileLoadEnd(_resource, _data)
                })
            }
        })

        // RGBE | HDR
        const rgbeLoader = new RGBELoader()

        this.loaders.push({
            extensions: ['hdr'],
            action: (_resource) => {
                rgbeLoader.load(_resource.source, (_data) => {
                    this.fileLoadEnd(_resource, _data)
                })
            }
        })
    }

    load(_resources = []) {
        for (const _resource of _resources) {

            this.toLoad++
            const extensionMatch = _resource.source.match(/\.([a-z]+)$/)
            console.log(extensionMatch)
            if (typeof extensionMatch[1] !== 'undefined') {
                const extension = extensionMatch[1]
                const loader = this.loaders.find((_loader) => _loader.extensions.find((_extension) => _extension === extension))

                if (loader) {
                    console.log(extensionMatch[1])
                    loader.action(_resource)
                }
                else {
                    console.log(`Cannot found loader for ${_resource}`)
                }
            }
            else {
                console.log(`Cannot found extension of ${_resource}`)
            }
        }
    }
    /**
     * File load end
     */
    fileLoadEnd(_resource, _data) {
        console.log(_resource.name)
        this.loaded++
        this.items[_resource.name] = _data

        this.trigger('fileEnd', [_resource, _data])
        this.updateCallback(this.loaded, this.groups.current.items.length);
        if (this.loaded === this.toLoad) {
            console.log("all end");
            this.trigger('end')
        }
    }
}

/******************************************************************************/
/*!
\brief  main loading manager
*/
/******************************************************************************/
class LoadingManager extends Component {

    state = {
        progressWidth: "0%",
        hideScreen: true
    }
    constructor(_options) {

        super(_options);
        this.updateBar = false
        this.loaded = -1;
        this.toLoad = 99999;
        this.loader = new Loader(
            {
                updateCallback: (itemsLoaded, itemsTotal) => {

                    this.setState({
                        progressWidth: (itemsLoaded / itemsTotal * 100) + "%"
                    });
                    if (itemsLoaded / itemsTotal > 0.99) {
                        this.setState({
                            hideScreen: false
                        });
                    }
                }
            }
        );
    }
    render() {
        return (
            <>
                <div className='loading-container' >
                    <div className='loading-background' style={{ background: !this.state.hideScreen ? "rgba(255, 255, 255,0)" : "rgb(21, 21, 21) " }}>
                        <div className='progress' style={{ zIndex: this.state.hideScreen ? 999 : -999, background: !this.state.hideScreen ? "rgba(51, 51, 51,0)" : "rgb(51, 51, 51) " }} >
                            <div className='progress2'
                                style={{ zIndex: this.state.hideScreen ? 999 : -999, width: this.state.progressWidth, background: !this.state.hideScreen ? "rgba(255, 255, 255,0) " : "rgb(255, 255, 255) " }} >
                            </div>
                        </div>
                    </div>
                </div>
            </>);

    }
}
export default LoadingManager;







