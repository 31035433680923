import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';

//renderer related
import Model from "../../Render/Model";
import Material from "../../Render/Material";
import LoadingManager from "../../Render/LoadingManager";
import Scene from "./Scene";
import { StandardShader } from "../../Shaders/StandardShader";
import { PropShader } from "../../Shaders/PropShader";
import { EmmisiveShader } from "../../Shaders/EmmisiveShader";

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
class Scene2 extends Scene {
  //constructor
  constructor(_options) {
    super(_options);
    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;

    // call back for mouse collision detection
    this.collision_about_me = _options.collision_about_me_callback;
    this.collision_motional = _options.collision_motional_callback;
    this.collision_contact = _options.collision_contact_callback;
    this.collision_project = _options.collision_project_callback;
    this.collision_close = _options.collision_close_callback;

    this.hide = false;
    this.current = 0;
    this.onbutton = false;
    //mouse data
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = Date.now();
    this.totalTime = 0.0;
    //camera vectors
    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);

    this.create_collision_box(new THREE.Vector3(0.1, -27, -1.75), 'collision_about_me');
    this.create_collision_box(new THREE.Vector3(0.1, -55, -1.75), 'collision_project');
    this.create_collision_box(new THREE.Vector3(0.1, -82, -1.75), 'collision_contact');

    this.storeTop = 0.0;
    //add event listeners
    this.loadListener();
    this.startRender();
    this.generateCamCurve();

    this.currPointLerped = new Vector3(0, 0, 0);
    this.currPointLookAtLerped = new Vector3(0, 0, 0);
    this.currPointLerped.set
      (-1.297, 1.948, 2.249);
    this.currPointLookAtLerped.set
      (-0.605, 0.054, 0.281);

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.loadAllModels();

  }
  create_collision_box = (position, name) => {
    this.cube = new Mesh(
      new BoxGeometry(15, 10, 15),
      new MeshBasicMaterial({ color: 0xffffffff }),
    );
    this.cube.position.set(position.x, position.y, position.z);
    this.cube.visible = false;
    this.cube.name = name;
    this.scene.add(this.cube);
  }

  generateCamCurve() {
    //position points
    var posPoints = [];

    posPoints.push(new THREE.Vector3(-40, -40, -40));
    posPoints.push(new THREE.Vector3(-40, -75, -40));
    posPoints.push(new THREE.Vector3(-40, -100, -40));


    this.spline = new THREE.CatmullRomCurve3(posPoints);
    this.points = this.spline.getPoints(200);

    //rotation points
    var lookAtPoints = [];
    lookAtPoints.push(new THREE.Vector3(0, -24, 0));
    lookAtPoints.push(new THREE.Vector3(0, -60, 0));
    lookAtPoints.push(new THREE.Vector3(0, -83, 0));
    ;

    this.lookAtSpline = new THREE.CatmullRomCurve3(lookAtPoints);
    this.lookAtPoints = this.lookAtSpline.getPoints(200);
  }
  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }
  loadAllModels() {
    // render given model
    this.loader.waitForAssets(() => {
      var bgTexture = this.loader.getAsset("bgTexture");
      var p1Texture = this.loader.getAsset("p1Texture");
      var p3Texture = this.loader.getAsset("p3Texture");
      var p4Texture = this.loader.getAsset("p4Texture");

      this.mainMaterial = new Material(
        {
          uniforms: {
            standardTexture: { value: bgTexture },

          },
          vertexShader: StandardShader.vertexShader,
          fragmentShader: StandardShader.fragmentShader
        }
      );
      this.pemMaterial = new Material(
        {
          uniforms: {
          },
          vertexShader: StandardShader.vertexShader,
          fragmentShader: EmmisiveShader.fragmentShader
        }
      );
      this.p1Material = new Material(
        {
          uniforms: {
            standardTexture: { value: p1Texture },
            downsideColor: { value: new THREE.Vector3(0 / 255, 3 / 255, 252 / 255) }
          },
          vertexShader: PropShader.vertexShader,
          fragmentShader: PropShader.fragmentShader
        }
      );

      this.p3Material = new Material(
        {
          uniforms: {
            standardTexture: { value: p3Texture },
            downsideColor: { value: new THREE.Vector3(252 / 255, 161 / 255, 3 / 255) }
          },
          vertexShader: PropShader.vertexShader,
          fragmentShader: PropShader.fragmentShader
        }
      );
      this.p4Material = new Material(
        {
          uniforms: {
            standardTexture: { value: p4Texture },
            downsideColor: { value: new THREE.Vector3(255 / 255, 0, 102 / 255) }
          },
          vertexShader: PropShader.vertexShader,
          fragmentShader: PropShader.fragmentShader
        }
      );
      //add main room model
      this.model = new Model({
        modelLink: 'bgModel',
        material: this.mainMaterial,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: 'mainScene'
      });
      var computer = this.detectDevice();
      console.log(computer);
      this.p1model = new Model({
        modelLink: 'p1Model',
        material: this.p1Material,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: 'p1mainScene'
      });

      this.p3model = new Model({
        modelLink: 'p3Model',
        material: this.p3Material,
        position: new THREE.Vector3(0, 26, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: 'p3mainScene'
      });
      this.p4model = new Model({
        modelLink: 'p4Model',
        material: this.p4Material,
        position: new THREE.Vector3(0, 26, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: 'p4mainScene'
      });
      this.pemmodel = new Model({
        modelLink: 'emModel',
        material: this.pemMaterial,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(1, 1, 1),
        scene: this.scene,
        name: 'pemmainScene'
      });
      console.log(this.mainMaterial);
      this.fullLoaded = true;
    });


  }
  componentDidMount() {
    console.log("scene 1 mounted")
    this.SceneMount();
  }
  componentWillUnmount() {
  }
  /******************************************************************************/
  /*!
  \brief  update per frame
  */
  /******************************************************************************/
  loadListener() {
    document.addEventListener("mousemove",
      (e) => {
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
      });
  }

  lerp_model(model, time) {
    if (model && model.all_model && model.all_model.length > 0) {
      for (var i = 0; i < model.all_model.length; ++i) {
        if (model.all_model[i].name !== "human") {
          var cosy = Math.cos(time * 0.001 + i * 0.676598) * 0.0125;
          model.all_model[i].position.setY(cosy + model.all_model_og_position[i].y);
        }
      }
    }
  }
  Update() {

    if (this.collision_close() === false) {
      this.onbutton = false;
    }
    console.log(this.collision_close);
    //update delta time
    var now = Date.now();
    this.dt = now - this.lastUpdate;
    this.lastUpdate = now;
    this.totalTime += this.dt;
    this.lerp_model(this.p1model, this.totalTime);
    this.lerp_model(this.p3model, this.totalTime);
    this.lerp_model(this.p4model, this.totalTime);


    if (this.storeTop > 0.1 && this.getState() === false) {
      console.log("scroll target : " + this.storeTop);
      document.getElementById('scc').scrollTop = this.storeTop;
      this.storeTop = 0;
    }
    const t2 = document.getElementById('scc').scrollTop / window.innerHeight / 2;
    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;
    if (this.getState() === false) {
      this.append = 0;
      this.lerpedMouse.x = MathUtils.lerp(this.lerpedMouse.x, this.mouseNormalized.x, 0.1 * 1 / this.dt * 1.5);
      this.lerpedMouse.y = MathUtils.lerp(this.lerpedMouse.y, this.mouseNormalized.y, 0.1 * 1 / this.dt * 1.5);
      this.lerpedMouseRaw.x = MathUtils.lerp(this.lerpedMouseRaw.x, this.mouse.x, 0.01 * 1 / this.dt * 1.5);
      this.lerpedMouseRaw.y = MathUtils.lerp(this.lerpedMouseRaw.y, this.mouse.y, 0.01 * 1 / this.dt * 1.5);
    }
    //get page position and lerp camera 

    this.pageLerp = t2 * 0.005;
    this.pageLerp2 = t2;
    this.pageLerp3 = t2;

    //modify camera position
    this.newCamera.threeCamera.getWorldDirection(this.camDirection);

    this.camSide.crossVectors(this.camDirection, this.newCamera.threeCamera.up);
    this.camUp.crossVectors(this.camSide, this.newCamera.threeCamera.up);

    this.camPos.set(0.0, 0.0, 0.0);
    this.camLookAt.set(0.0, 0, 0);

    this.camSide.multiplyScalar(this.lerpedMouse.x * 25);

    var index = MathUtils.clamp(this.pageLerp2, 0.0, 0.99);

    let currPoint = this.points[parseInt(index * 200, 10)];
    let currPointLookAt = this.lookAtPoints[parseInt(index * 200, 10)];
    this.tempPoint = currPoint;
    this.tempPointLookAt = currPointLookAt;
    if (this.getState() === false) {
      this.sendPoint.copy(this.camPos);
      this.sendPoint.add(currPoint);
      this.sendPoint.add(this.camDirection);
      this.sendPoint.add(this.camSide);
      this.sendPoint.add(this.camUp);


      this.sendPointLookAt.copy(this.camLookAt);
      this.sendPointLookAt.add(currPointLookAt);
    } else {
      this.sendPoint = this.camPosOverride;
      this.sendPointLookAt = this.lookPosOverride;
    }

    this.currPointLerped.lerp(this.sendPoint, 0.05);
    this.currPointLookAtLerped.lerp(this.sendPointLookAt, 0.05);

    if (this.getState() === 0) {

      this.clickTimeout++;
    } else {
      this.clickTimeout = 0;
    }
    this.newCamera.setPosition(this.currPointLerped);

    this.newCamera.threeCamera.lookAt(this.currPointLookAtLerped);


    if (!this.boot && this.newCamera !== undefined) {
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();

      const t = () => {
        if (this.onbutton || this.getState() === true) return;

        raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
        const intersects = raycaster.intersectObjects(this.scene.children);
        for (let i = 0; i < intersects.length; i++) {

          if (intersects[i].object.name === 'collision_about_me') {
            this.onbutton = true;
            this.storeTop = document.getElementById('scc').scrollTop;
            this.camPosOverride = new THREE.Vector3
              (-5, -22, -5);
            this.lookPosOverride = new THREE.Vector3
              (-0.25, -24, 0.25);
            this.collision_about_me();
            break;
          }

          if (intersects[i].object.name === 'collision_project') {
            this.onbutton = true;
            this.storeTop = document.getElementById('scc').scrollTop;
            this.camPosOverride = new THREE.Vector3
              (-5, -50, -5);
            this.lookPosOverride = new THREE.Vector3
              (-0.25, -49, 0.25);
            this.collision_project();
            break;
          }
          if (intersects[i].object.name === 'collision_contact') {
            this.onbutton = true;
            this.storeTop = document.getElementById('scc').scrollTop;
            this.camPosOverride = new THREE.Vector3
              (-5, -77, -5);
            this.lookPosOverride = new THREE.Vector3
              (-0.25, -76, 0.25);
            this.collision_contact();
            break;
          }
        }
      }

      const f = (delta) => {

        this.scrollDelta -= delta * 0.0125;

        const scrollMin = 20;
        const scrollMax = 75;
        if (this.scrollDelta < scrollMin) {
          this.scrollDelta = scrollMin;
        }
        if (this.scrollDelta > scrollMax) {
          this.scrollDelta = scrollMax;
        }
      }
      function onMouseWheel(event) {
        f(event.deltaY);
      }

      function onMouseClick(event) {
        t();
      }
      function onMouseMove(event) {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
      }
      window.addEventListener('mousewheel', onMouseWheel, false);
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('mousemove', onMouseMove, false);
      this.boot = true;
    }
  }
}

export default Scene2
