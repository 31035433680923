import '../Theme/Theme';
import './Home.css';
import '../../src/Global.css';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import XIcon from '@mui/icons-material/X';
export const panel_contact = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Typography fontFamily="Akira"
                    paddingTop="105px"
                    align="center"
                    color={'#ffffffff'}
                    variant="h3"
                    lineHeight={'1.5rem'}
                    fontSize="3.5rem"
                    fontWeight="5000"
                    fontStyle={'normal'}>
                    CONTACT
                </Typography>
                <Typography fontFamily="DMSans"
                    paddingBottom="50px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'3.5rem'}
                    fontSize="1.0rem"

                    fontWeight="100"
                    fontStyle={'normal'}>
                    How to reach out
                </Typography>

                <Link
                    display={'flex'}
                    underline="none"
                    component="a"
                    href={`https://x.com/artcode_theon/`}
                    color='#ffffffff'
                    fontFamily={'Akira'}
                    style={{ alignItems: 'center', justifyContent: 'center', letterSpacing: '5px' }}
                >
                    <Box display={'flex'} padding="30px" alignItems={'center'} justifyContent={'center'}>
                        <XIcon style={{ alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px', color: '#ffffffff' }}></XIcon>
                        <Typography fontFamily={'DMSans'}>
                            artcode_theon
                        </Typography>
                    </Box>
                </Link>
                <Link
                    display={'flex'}
                    underline="none"
                    component="a"
                    href={`mailto:theon@artcodestudio.co`}
                    color='#ffffffff'
                    fontFamily={'Akira'}
                    style={{ alignItems: 'center', justifyContent: 'center', letterSpacing: '5px' }}
                >
                    <Box display={'flex'} padding="30px" alignItems={'center'} justifyContent={'center'}>
                        <EmailIcon style={{ alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px', color: '#ffffffff' }}></EmailIcon>
                        <Typography fontFamily={'DMSans'}>
                            theon@artcodestudio.co
                        </Typography>
                    </Box>
                </Link>
            </div>
        </>
    )
}
