import * as THREE from "three";

const PropShader = {
	name: 'PropShader',
	uniforms: {
	},

	vertexShader: [

		`
		varying vec2 vUv;
		varying vec3 vNormal;

		void main()
		{
			vec4 modelPosition = modelMatrix * vec4(position, 1.0);
			vec4 viewPosition = viewMatrix * modelPosition;
			vec4 projectionPosition = projectionMatrix * viewPosition;
			gl_Position = projectionPosition;

			vNormal = normal;
			vUv = uv;
		}`

	].join("\n"),

	fragmentShader: [
		`
		uniform sampler2D standardTexture;
		uniform vec3 downsideColor;

		varying vec2 vUv;
		varying vec3 vNormal;
		void main()
		{
			vec3 sunDir =normalize(vec3(0,1,0));
			float directionalLightIntensity = max(0.0, dot(vNormal, normalize(-sunDir)));
			vec3 sunDir2 =normalize(vec3(0.3,0,1));
			float directionalLightIntensity2 = max(0.0, dot(vNormal, normalize(-sunDir2)));
			vec3 Color = texture2D(standardTexture, vUv).rgb;
			Color /=2.0;
			Color += vec3(0.25,0.25,0.25) + downsideColor * directionalLightIntensity;
			gl_FragColor = vec4(Color, 1.0);
		}
		`

	].join("\n")

};
export { PropShader };
