/*****************************************************************************/
/*!
\file Scene.js
\author Theon Teo
\par email: theonteo96@gmail.com
\date 2021
\brief
This project contains portfolio / web-mobile responsive application
\Not for distribution
*/
/*****************************************************************************/
import React, { Component } from "react";
import * as THREE from "three";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { VignetteShader } from "../../Shaders/VignetteShader"
//renderer related
import './Scene.css'
import Camera from "../../Render/Camera";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
class Scene extends Component {
  constructor(_options) {
    super(_options);
    function detectDevice() {
      if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
      ) {
        return 'phone';
      } else {
        return 'computer';
      }
    }
    //init variables
    var device = detectDevice();

    this.usePostprocess = (device == 'computer');
    this.container = [];
    this.pageLerp = 0;
    this.scene = new THREE.Scene();

    //add Camera
    this.setCamera(1200, 768);
  }
  /******************************************************************************/
  /*!
  \brief  create new camera
  */
  /******************************************************************************/
  setCamera(width, height) {
    //add Camera
    this.newCamera =
      new Camera({
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0, 0, 0),
        width: width,
        height: height
      });
  }
  /******************************************************************************/
  /*!
  \brief  component mounted - three.js
  */
  /******************************************************************************/
  SceneMount() {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;


    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ castShadow: true, antialias: true, alpha: true });
    this.renderer.setClearColor("#111111", 1);
    this.renderer.setSize(width, height);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    this.mount.appendChild(this.renderer.domElement);
    this.addListener();
    //add Camera
    this.setCamera(width, height);

    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.setPostProcess();
  }

  setPostProcess() {
    this.postProcess = {}


    /**
     * Render pass
     */
    this.postProcess.renderPass = new RenderPass(this.scene, this.newCamera.threeCamera)

    //bloom Pass
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 0.3, 2.0, 0.25)
    bloomPass.enabled = true
    var vignette = new ShaderPass(VignetteShader);
    vignette.uniforms["resolution"].value = new THREE.Vector2(window.innerWidth, window.innerHeight);
    vignette.uniforms["horizontal"].value = false; // default is false
    vignette.uniforms["radius"].value = .5; // default is 0.75
    vignette.uniforms["softness"].value = .3; // default is 0.3
    vignette.uniforms["gain"].value = 0.15; // default is 0.9
    /**
     * Effect composer
     */
    const RenderTargetClass = THREE.WebGLRenderTarget
    this.renderTarget = new RenderTargetClass(
      window.innerWidth,
      window.innerHeight,
      {
      }
    )
    this.postProcess.composer = new EffectComposer(this.renderer, this.renderTarget)
    this.postProcess.composer.setSize(window.innerWidth, window.innerHeight)
    this.postProcess.composer.setPixelRatio(1.5)
    this.postProcess.composer.addPass(this.postProcess.renderPass);
    this.postProcess.composer.addPass(bloomPass);
    this.postProcess.composer.addPass(vignette);
    this.postProcess.composer.addPass(new SMAAPass(window.innerWidth, window.innerHeight));

  }
  addListener() {
    window.addEventListener('resize', () => {
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    });
  }

  /******************************************************************************/
  /*!
  \brief  start main render - derived class should start
  */
  /******************************************************************************/
  startRender() {
    //loop through all models and add to scene container

    this.container.forEach(function (item, index) {
      this.scene.add(item);
    });
    this.scene.castShadow = true;

    //render scene
    this.renderScene();

    console.log(this.scene);

    //start animation
    this.start();
  }

  /******************************************************************************/
  /*!
  \brief  component unmounted - three.js
  */
  /******************************************************************************/
  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }
  start = () => {
    if (!this.frameId)
      this.frameId = requestAnimationFrame(this.animate);

  };
  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    //main scene update function
    this.Update();
    this.renderScene();

    this.frameId =
      window.requestAnimationFrame(this.animate);
  };
  /******************************************************************************/
  /*!
  \brief render final scene
  */
  /******************************************************************************/
  renderScene = () => {
    if (this.renderer) {

      if (this.usePostprocess) {
        this.postProcess.composer.render()
      }
      else {
        this.renderer.render
          (this.scene, this.newCamera.threeCamera);
      }
    }
  };


  /******************************************************************************/
  /*!
  \brief  return - composite html
  */
  /******************************************************************************/
  render() {
    return (
      <div className='render-window'
        ref={mount => {
          this.mount = mount;
        }}
      />
    );
  }
}
export default Scene;
