import * as THREE from "three";

const VignetteShader = {
    name: 'VignetteShader',
    uniforms: {
        "tDiffuse": { type: "t", value: null },
        "resolution": { type: "v2", value: new THREE.Vector2() },
        "gain": { type: "f", value: 0.9 },
        "horizontal": { type: "bool", value: true },
        "radius": { type: "f", value: 0.15 },
        "softness": { type: "f", value: 0.03 },
    },

    vertexShader: [
        "varying vec2 vUv;",
        "void main() {",
        "    vUv = uv;",
        "    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
        "}"
    ].join("\n"),

    fragmentShader: [
        "uniform sampler2D tDiffuse;",
        "uniform vec2 resolution;",
        "uniform float gain;",
        "uniform float radius;",
        "uniform float softness;",
        "uniform bool horizontal;",
        "varying vec2 vUv;",

        "float rand(vec2 co){",
        "    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);",
        "}",

        "void main() {",
        "    vec4 color = texture2D(tDiffuse, vUv);",
        "    vec3 c = color.rgb;",
        "    float noise = rand(gl_FragCoord.xy) * .05;",

        // determine center
        "    vec2 position = (gl_FragCoord.xy / resolution) - vec2(0.5);",

        // Calculate vignette effect
        "    float len = length(position) * gain;",
        "    float vignette = smoothstep(radius, radius - softness, len);",

        "    gl_FragColor = vec4(c * vignette, 1.0);",
        "}"
    ].join("\n")
};

export { VignetteShader };
