

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';

const Topbar = () => {
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Link
                    underline="none"
                    component="a"
                    href="/"
                    fontFamily={'Akira'}
                    style={{ letterSpacing: '5px' }}
                >
                    <Typography fontFamily="Akira"
                        paddingBottom="30px"
                        paddingTop="25px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'0.0rem'}
                        fontSize="2.5rem"
                        fontWeight="100"
                        fontStyle={'italic'}>
                        THEON
                    </Typography>
                    <Typography fontFamily="Akira"
                        paddingBottom="0px"
                        paddingTop="0px"
                        display={'flex'}
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'0.0rem'}
                        fontSize="2.5rem"
                        fontWeight="100"
                        fontStyle={'normal'}>
                        IO
                        <Typography
                            fontFamily="Akira"
                            color={'#FFFFFFFF'}>
                            .</Typography>
                    </Typography>

                </Link>
            </Box>
        </Box>
    );
};

Topbar.propTypes = {
};

export default Topbar;
