export default [
    {
        name: 'base',
        data: {},
        items:
            [
                { name: 'bgTexture', source: '/assets/text_bg.jpg', type: 'texture' },
                { name: 'p1Texture', source: '/assets/text_p1.jpg', type: 'texture' },
                { name: 'p2Texture', source: '/assets/text_p2.jpg', type: 'texture' },
                { name: 'p3Texture', source: '/assets/text_p3.jpg', type: 'texture' },
                { name: 'p4Texture', source: '/assets/text_p4.jpg', type: 'texture' },
                { name: 'bgModel', source: '/assets/bg_1.glb' },
                { name: 'emModel', source: '/assets/p_em.glb' },
                { name: 'p1Model', source: '/assets/p_1.glb' },
                { name: 'p2Model', source: '/assets/p_2.glb' },
                { name: 'p3Model', source: '/assets/p_3.glb' },
                { name: 'p4Model', source: '/assets/p_4.glb' },
            ]
    }
]
