import '../Theme/Theme';
import './Home.css';
import '../../src/Global.css';
import { Typography } from '@mui/material';
import { CardMedia } from '@mui/material';
import Box from '@mui/material/Box';

export const panel_about_me = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>

                <Typography fontFamily="Akira"
                    paddingTop="105px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'2.0rem'}
                    fontSize="2.5rem"
                    fontWeight="100"
                    fontStyle={'normal'}>
                    THEON TEO
                </Typography>
                <Typography fontFamily="DMSans"
                    paddingTop="20px"
                    paddingBottom="50px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'1.0rem'}
                    fontSize="1.0rem"
                    letterSpacing={'0.5rem'}
                    fontWeight="100"

                    fontStyle={'normal'}>
                   BUILDING CREATIVE AND IMMERSIVE EXPERIENCES
                </Typography>
                <Typography
                    style={{ backgroundColor: '#1a27e8ff' }}
                    fontFamily="Akira"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="25px"
                    marginTop="25px"

                    borderRadius={'0px'}
                    align="left"
                    color={'#ffffffff'}
                    variant="h3"
                    fontSize="1rem"
                    fontWeight="600">
                    ///  AN INTRODUCTION
                </Typography>
                <div style={{ padding: 25 }}>
                    <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'center'} color='##ffffffff'>
                        <CardMedia
                            image={'profile.jpg'}
                            style={{

                                justifyContent: 'center',
                                alignItems: 'center',

                                margin: '5px',
                                color: '#ffffffff',
                                height: `200px`,
                                width: `200px`,
                                borderRadius: '5px',
                            }} />
                    </Box>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2.5rem'}

                        fontSize="1rem"
                        fontWeight="100">
                        Greetings, <b>Theon here!</b>
                    </Typography>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        As a passionate software developer, I thrive on crafting innovative applications, backend systems, and dynamic websites that push the boundaries of technology.
                    </Typography>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        My expertise spans a wide range of technical domains, from backend software development to intricate projects involving 3D graphics.
                    </Typography>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        After some endevour in full-time software engineering, i will be focusing on using my skillsets to provide value for business needs.
                    </Typography>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        I immerse myself in the world of interactive front-end design, channeling my creativity into captivating user experiences.
                    </Typography>
                </div>
              

            </div>

        </>
    )
}
