

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const Bottombar = () => {
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Link
                    underline="none"
                    component="a"
                    href="/"

                    fontFamily={'Akira'}
                    style={{ letterSpacing: '5px' }}
                >

                </Link>
            </Box>
            <Box display={'flex'} padding="0px" alignItems={'right'}>
               <Box paddingRight="0px">
                    <Link
                        underline="none"
                        component="a"
                        href={`mailto:theon@artcodestudio.co`}

                        fontFamily={'Akira'}
                        style={{ letterSpacing: '5px' }}
                    >
                        <EmailIcon style={{ width: '30px', height: '30px', color: '#ffffffff' }}></EmailIcon>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

Bottombar.propTypes = {
};

export default Bottombar;
