import React from 'react';
import '../Theme/Theme';
import Card from '@mui/material/Card';
import './Home.css';

import Scene2 from '../Components/Scenes/Scene2';
import { Grid } from '@mui/material';
import Content from './Content';
import Topbar from '../Components/TopBar';
import Bottombar from '../Components/BottomBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Container from '../Components/Container';
import LoadingManager from '../Render/LoadingManager';
const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_ABOUT_ME,
  CONTENT_STAGE_MOTIONAL,
  CONTENT_STAGE_CONTACT,
  CONTENT_STAGE_PROJECT
}
var t = false;
const Scene2Page: React.FC = () => {

  const [contentValue, setContentValue] = React.useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }
  const CloseContent = () => {
    t = false;
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }
  const OpenContent_Stage_AboutMe = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_ABOUT_ME);
    }
  }
  const OpenContent_Stage_Motional = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_MOTIONAL);
    }
  }
  const OpenContent_Stage_Contact = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_CONTACT);
    }
  }
  const OpenContent_Stage_Project = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_PROJECT);
    }
  }
  const ReturnState = () => {
    return t;
  }

  return (
    <>
      <LoadingManager />
      <div className="pageGlobal">
        <Grid container style={{
          position: 'fixed', // Fix the Three.js viewport position
          zIndex: 1, // Place it behind the content
          height: '100vh',
          width: '100vw',
        }} >
          <Scene2
            collision_about_me_callback={OpenContent_Stage_AboutMe}
            collision_motional_callback={OpenContent_Stage_Motional}
            collision_contact_callback={OpenContent_Stage_Contact}
            collision_project_callback={OpenContent_Stage_Project}
            collision_close_callback={ReturnState}
            statecallback={ReturnState}
          >
          </Scene2 >
        </Grid>
        {contentValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
          <>
            <HideOnScroll>
              <AppBar
                position={'fixed'}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: '#00000000',
                }}
              >
                <Container paddingY={{ xs: 1 / 4, sm: 2 }}>
                  <Topbar />
                </Container>
              </AppBar>
            </HideOnScroll>
            <AppBar
              position={'fixed'}
              sx={{
                top: 'auto', bottom: 0,
                boxShadow: 'none',
                backgroundColor: '#00000000',
              }}
            >
              <Container paddingY={{ xs: 1 / 4, sm: 2 }}>
                <Bottombar />
              </Container>
            </AppBar>
          </>}
        <Grid id='scc' container style={{
          position: 'fixed', // Fix the Three.js viewport position
          height: '100vh',
          width: '100vw',
          zIndex: 2, // Place it behind the content
          overflowY: 'scroll',
          overflowX: 'hidden'
        }} >
          {
            <>
              {contentValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
                <>
                  <Card
                    style={{
                      position: 'absolute',
                      backgroundColor: '#00000000',
                      width: '100vw',
                      height: '300%',
                      overflowY: 'scroll'

                    }}
                  >
                  </Card>
                </>
              }
            </>
          }
        </Grid>
        <Grid container item direction="column" style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '200%', // Fill the full viewport height
          overflowY: 'scroll', // Allow vertical scrolling
        }}>
          <Content
            content_panel_value={contentValue}
            content_open={contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN}
            content_close={CloseContent}>
          </Content>
        </Grid>
      </div>
    </>
  );
};
export default Scene2Page;
