import '../Theme/Theme';
import './Home.css';
import '../../src/Global.css';
import { Typography, Link, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';

const projects = [
    {
        name: "Art Code",
        description: "Design agency specializing in interactive web experiences",
        image: "artcode.jpg",
        link: ""
    },
    // Add more projects here
];

export const panel_project = () => {
    return (
        <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>
            <Typography fontFamily="Akira"
                paddingTop="105px"
                align="center"
                color={'#ffffffFF'}
                variant="h3"
                lineHeight={'1.5rem'}
                fontSize="2.5rem"
                fontWeight="100"
                fontStyle={'normal'}>
                WHAT AM I BUILDING
            </Typography>
            <Typography fontFamily="DMSans"
                paddingBottom="50px"
                align="center"
                color={'#ffffffFF'}
                variant="h3"
                lineHeight={'3.5rem'}
                fontSize="1.0rem"
                fontWeight="100"
                fontStyle={'normal'}>
                Works that I have done
            </Typography>
            <Typography
                style={{ backgroundColor: '#eb8934ff' }}
                fontFamily="Akira"
                paddingTop="10px"
                paddingBottom="10px"
                paddingLeft="25px"
                marginTop="25px"
                marginBottom="25px"
                borderRadius={'0px'}
                align="left"
                color={'#ffffffff'}
                variant="h3"
                fontSize="1rem"
                fontWeight="600">
                ///  LISTED PROJECTS
            </Typography>
            <Grid container spacing={4} direction="column">
                {projects.map((project, index) => (
                    <Grid item xs={12} key={index}>
                        <Card style={{ backgroundColor: '#00000055', color: '#00000000' }}>
                            <CardMedia
                                component="img"
                                height="250"
                                image={project.image}
                                alt={project.name}
                            />
                            <CardContent>
                                <Typography fontFamily="DMSans"
                                    align="left"
                                    color={'#FFFFFFFF'}
                                    variant="h5"
                                    fontWeight="600">
                                    {project.name}
                                </Typography>
                                <Typography fontFamily="DMSans"
                                    align="left"
                                    color={'#FFFFFFFF'}
                                    variant="body2"
                                    fontWeight="100">
                                    {project.description}
                                </Typography>
                                <Link
                                    display={'block'}
                                    underline="none"
                                    component="a"
                                    href={project.link}
                                    color='#ffffffff'
                                    fontFamily={'Akira'}
                                    style={{ marginTop: '15px', textAlign: 'center' }}
                                >
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}
