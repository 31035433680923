/*****************************************************************************/
/*!
\file Home.js
\date 2021
\brief
\Not for distribution
*/
/*****************************************************************************/

import { panel_about_me } from './ContentAbout';
import { panel_motional } from './ContentMotional';
import { panel_contact } from './ContentContact';
import { panel_project } from './ContentProject';

import React from 'react';
import '../Theme/Theme';
import './Home.css';
import '../../src/Global.css';
import { Button } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

export interface ContentProp {
  content_open: any
  content_close: any
  content_panel_value: any
}
export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_ABOUT_ME,
  CONTENT_STAGE_MOTIONAL,
  CONTENT_STAGE_CONTACT,
  CONTENT_STAGE_PROJECT

}
const styles = {
  customMaxWidth: {
    maxWidth: "none" // arbitrary value
  }
};
const Content: React.FC<ContentProp> = (prop: ContentProp) => {

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  React.useEffect(() => {
    setOpen(false);
    setScroll('paper');
  }, []);
  React.useEffect(() => {

    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <>
      <Grid container item direction="column" style={{ boxShadow: 'none' }}>
        <ThemeProvider theme={darkTheme}>
          <Dialog
            classes={{ paperScrollPaper: "none" }}
            BackdropProps={{
              style: {
                backgroundColor: 'linear-gradient(to right bottom,#aaaaaaff,#333333ff)',
                backdropFilter: 'blur(5px)',
                boxShadow: 'none',
              },
            }}

            open={prop.content_open}
            fullScreen

            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{

              sx: {
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
                "&::-webkit-scrollbar": {
                  width: 20
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "orange"
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "red",
                  borderRadius: 2
                }
              },
              style: {
                maxWidth: '800px',
                background: '#00000055',
                boxShadow: "none",
              },
            }}
          >

            {prop.content_panel_value === ContentPanel.CONTENT_STAGE_ABOUT_ME && panel_about_me()}
            {prop.content_panel_value === ContentPanel.CONTENT_STAGE_MOTIONAL && panel_motional()}
            {prop.content_panel_value === ContentPanel.CONTENT_STAGE_CONTACT && panel_contact()}
            {prop.content_panel_value === ContentPanel.CONTENT_STAGE_PROJECT && panel_project()}
            <DialogActions style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              {
                prop.content_panel_value !== ContentPanel.CONTENT_STAGE_HIDDEN &&
                <>
                  <Button
                    variant='contained'
                    onClick={prop.content_close}

                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '30px',
                      marginBottom: '30px',
                      height: '40px',
                      width: '40px',
                      fontFamily: 'DMSans',
                      fontSize: '1.0rem',
                      fontWeight: '100',
                      color: '#000000',
                      backgroundColor: '#ffffffff',
                      borderRadius: '30px',
                      boxShadow: 'none',
                    }}>
                    X</Button>
                </>
              }

            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </Grid>
    </>
  );
};
export default Content;
