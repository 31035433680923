import '../Theme/Theme';
import './Home.css';
import '../../src/Global.css';
import { Typography } from '@mui/material';

export const panel_motional = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Typography fontFamily="Akira"
                    paddingTop="105px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'1rem'}
                    fontSize="2rem"
                    fontWeight="100"
                    fontStyle={'normal'}>
                    CAREER
                </Typography>
                <Typography fontFamily="DMSans"
                    paddingBottom="50px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'3.5rem'}
                    fontSize="1.0rem"
                    fontWeight="100"
                    fontStyle={'normal'}>
                    Work and experiences
                </Typography>
                <Typography
                    style={{ backgroundColor: '#7300ffff' }}
                    fontFamily="Akira"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="25px"
                    marginTop="25px"
                    marginBottom="25px"
                    borderRadius={'0px'}
                    align="left"
                    color={'#ffffffff'}
                    variant="h3"
                    fontSize="1rem"
                    fontWeight="600">
                    ///  MOTIONAL
                </Typography>
                <div style={{ padding: 25 }}>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        Years active : 2022-2024
                    </Typography>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        Throughout my professional odyssey at Motional, my trajectory evolved dynamically. initially applying as a frontend developer intern, I secured a RVA backend role before culminating in immersion within the realm of Smart Tunnel embedded systems.
                    </Typography>
                </div>
                <Typography
                    style={{ backgroundColor: '#7300ffff' }}
                    fontFamily="Akira"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="25px"
                    marginTop="25px"
                    marginBottom="25px"
                    borderRadius={'0px'}
                    align="left"
                    color={'#ffffffff'}
                    variant="h3"
                    fontSize="1rem"
                    fontWeight="600">
                    ///  REMOTE VEHICLE ASSISTANCE
                </Typography>
                <div style={{ padding: 25 }}>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        Within this multifaceted environment, my responsibilities spanned across general software development, entailing the seamless integration of novel features into our backend application while upholding rigorous standards through comprehensive unit and integration testing protocols.
                    </Typography>

                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        My journey led me to the esteemed Remote Vehicle Assistance team, followed by a pivotal role within the pioneering Smart Tunnel team, where we investigate connectivity solution within our RVA workflow.
                    </Typography>
                </div>
                <Typography
                    style={{ backgroundColor: '#7300ffff' }}
                    fontFamily="Akira"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="25px"
                    marginTop="25px"
                    marginBottom="25px"
                    borderRadius={'0px'}
                    align="left"
                    color={'#ffffffff'}
                    variant="h3"
                    fontSize="1rem"
                    fontWeight="600">
                    ///  SKILL SETS
                </Typography>

                <Typography fontFamily="DMSans"
                    paddingBottom="15px"
                    paddingTop="15px"
                    align="left"
                    color={'#FFFFFFFF'}
                    variant="h3"
                    lineHeight={'2rem'}
                    fontSize="1rem"
                    fontWeight="100">
                    <ul>
                        <li>
                            C++
                        </li>
                        <li>
                            C
                        </li>
                        <li>
                            C#
                        </li>
                        <li>
                            GO
                        </li>
                        <li>
                            Bash
                        </li>
                        <li>
                            Python
                        </li>
                        <li>
                            JavaScript
                        </li>
                        <li>
                            TypeScript
                        </li>
                        <li>
                            HTML
                        </li>
                        <li>
                            CSS
                        </li>
                        <li>
                            MUI
                        </li>
                        <li>
                            ImGui
                        </li>
                        <li>
                            OpenGL
                        </li>
                        <li>
                            Qt
                        </li>
                        <li>
                            three.js
                        </li>
                        <li>
                            Netlify
                        </li>
                    </ul>
                </Typography>
            </div>
        </>
    )
}
